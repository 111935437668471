import { useEffect, useState } from "react";
import styles from "./howToMakeYourQuiz.module.scss";
import salesFunnelStyles from "./howToMakeYourQuizSalesFunnel.module.scss";
import sliderImage1 from "src/assets/homePage/slider1.png";
import sliderImage2 from "src/assets/homePage/slider2.png";
import sliderImage3 from "src/assets/homePage/slider3.png";

const HowToMakeYourQuiz: React.FC<any> = ({
  title = "How to make your quiz",
  subtitle,
  isSalesFunnel,
}: any) => {
  const [currentSliderStep, setCurrentSliderStep] = useState(1);
  const [currentSliderImage, setCurrentSliderImage] = useState(sliderImage1);

  useEffect(() => {
    switch (currentSliderStep) {
      case 1:
        setCurrentSliderImage(sliderImage1);
        break;
      case 2:
        setCurrentSliderImage(sliderImage2);
        break;
      case 3:
        setCurrentSliderImage(sliderImage3);
        break;
    }
  }, [currentSliderStep]);

  return (
    <div className={styles.wrapper}>
      <h4 dangerouslySetInnerHTML={{ __html: title }} />
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      <div
        className={
          isSalesFunnel ? salesFunnelStyles.sliderWrapper : styles.sliderWrapper
        }
      >
        <div className={isSalesFunnel ? salesFunnelStyles.imgWrap : ""}>
          <img
            src={currentSliderImage}
            alt={`form builder ${currentSliderStep} step`}
          />
        </div>
        <ul>
          <li
            className={
              currentSliderStep === 1
                ? isSalesFunnel
                  ? salesFunnelStyles.selectedList
                  : styles.selectedList
                : ""
            }
            onClick={() => setCurrentSliderStep(1)}
          >
            <div
              className={`${
                isSalesFunnel ? salesFunnelStyles.listNumber : styles.listNumber
              } ${
                currentSliderStep === 1
                  ? isSalesFunnel
                    ? salesFunnelStyles.selectedListNumber
                    : styles.selectedListNumber
                  : ""
              }`}
            >
              1
            </div>
            <div className={styles.listText}>
              <p
                className={
                  isSalesFunnel ? salesFunnelStyles.listTitle : styles.listTitle
                }
              >
                Upload your logo
              </p>
              <p
                className={
                  isSalesFunnel
                    ? salesFunnelStyles.listSubtitle
                    : styles.listSubtitle
                }
              >
                Simply upload your companies logo.
              </p>
            </div>
          </li>
          <li
            className={
              currentSliderStep === 2
                ? isSalesFunnel
                  ? salesFunnelStyles.selectedList
                  : styles.selectedList
                : ""
            }
            onClick={() => setCurrentSliderStep(2)}
          >
            <div
              className={`${
                isSalesFunnel ? salesFunnelStyles.listNumber : styles.listNumber
              } ${
                currentSliderStep === 2
                  ? isSalesFunnel
                    ? salesFunnelStyles.selectedListNumber
                    : styles.selectedListNumber
                  : ""
              }`}
            >
              2
            </div>
            <div className={styles.listText}>
              <p
                className={
                  isSalesFunnel ? salesFunnelStyles.listTitle : styles.listTitle
                }
              >
                Enter your requirements
              </p>
              <p
                className={
                  isSalesFunnel
                    ? salesFunnelStyles.listSubtitle
                    : styles.listSubtitle
                }
              >
                Input the questions you want to ask your prospects. We recommend
                3-5 questions.
              </p>
            </div>
          </li>
          <li
            className={
              currentSliderStep === 3
                ? isSalesFunnel
                  ? salesFunnelStyles.selectedList
                  : styles.selectedList
                : ""
            }
            onClick={() => setCurrentSliderStep(3)}
          >
            <div
              className={`${
                isSalesFunnel ? salesFunnelStyles.listNumber : styles.listNumber
              } ${
                currentSliderStep === 3
                  ? isSalesFunnel
                    ? salesFunnelStyles.selectedListNumber
                    : styles.selectedListNumber
                  : ""
              }`}
            >
              3
            </div>
            <div className={styles.listText}>
              <p
                className={
                  isSalesFunnel ? salesFunnelStyles.listTitle : styles.listTitle
                }
              >
                Publish your LeadQuiz
              </p>
              <p
                className={
                  isSalesFunnel
                    ? salesFunnelStyles.listSubtitle
                    : styles.listSubtitle
                }
              >
                Publish your LeadQuiz with your custom domain. You can now use
                LeadQuiz for Facebook Advertising, Google Ads, QR Codes, and so
                much more!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HowToMakeYourQuiz;
