import { useEffect } from "react";
import styles from "./salesFunnelPricing.module.scss";
import Payments from "../../homePage/payments/payments";

const SalesFunnelPricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>Our Pricing</p>
        <p className={styles.subtitle}>
          There is great opportunity to get 14 day free trial
        </p>
      </div>
      <div className={styles.paymentsWrap}>
        <Payments isTrial />
      </div>
    </div>
  );
};

export default SalesFunnelPricing;
