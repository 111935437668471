import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./datePickerComponent.module.scss";
import { IDatePickerComponent } from "../interfaces/interfaces";

const DatePickerComponent: React.FC<IDatePickerComponent> = ({
  value,
  handleChange,
}: IDatePickerComponent) => {
  return (
    <div className={styles.datePickerWrapper}>
      <DatePicker
        selected={value as Date}
        onChange={handleChange as any}
        dateFormat="MM/dd/yyyy"
        placeholderText="MM/DD/YYYY"
        className={styles.datePicker}
        calendarClassName={styles.calendarStyle}
        wrapperClassName="calendarStyle"
      />
    </div>
  );
};

export default DatePickerComponent;
