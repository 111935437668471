import { useDispatch } from "react-redux";
import styles from "./top.module.scss";
import {
  setIsShowCalendlyModalAC,
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const Top = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.pricingTopOuterWrapper}>
      <div className={styles.pricingTopWrapper}>
        <h1>Start generating quality leads.</h1>
        <p>
          Replace your Facebook forms, and confusing websites with an
          interactive form. Allowing your business to increase lead quality and
          convert more clients.
        </p>
        <div className={styles.getStartedButton}>
          <button
            onClick={
              token
                ? () => dispatch(setIsShowPricingModalAC(true))
                : () => dispatch(setIsShowRegisterModalAC(true))
            }
          >
            Get started today
          </button>
        </div>
        <button
          className={styles.demoButton}
          onClick={() =>
            dispatch(
              setIsShowCalendlyModalAC({
                show: true,
                url: process.env.REACT_APP_CALENDLY_DEMO_URL!,
              })
            )
          }
        >
          Schedule a demo
        </button>
      </div>
    </div>
  );
};

export default Top;
