import styles from "./trialPricingSlider.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TrialPricingSlider: React.FC<any> = ({
  paymentPlans,
  enterprisePlan,
}: any) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  if (paymentPlans && !paymentPlans.includes(enterprisePlan)) {
    paymentPlans.push(enterprisePlan);
  }

  return (
    <div className={styles.paymentsWrapperSlider}>
      <Slider {...sliderSettings}>
        {paymentPlans &&
          paymentPlans.map((el: any) => (
            <div className={styles.sliderItem}>{el}</div>
          ))}
      </Slider>
    </div>
  );
};

export default TrialPricingSlider;
