import { Link, To } from "react-router-dom";
import { IAlertMessage } from "../interfaces/interfaces";
import styles from "./alertMessage.module.scss";
import checkIcon from "src/assets/checkCircle.svg";
import closeBtn from "src/assets/cross.svg";

const AlertMessage: React.FC<IAlertMessage> = ({
  message,
  handleAction,
  onClose,
}: IAlertMessage) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrap}>
        <img src={checkIcon} alt="check" />
        {message}
      </div>
      <div className={styles.buttonsWrap}>
        <Link className={styles.actionBtn} to={handleAction as To}>
          Upgrade My Plan with Expert Onboarding
        </Link>
        <button className={styles.closeBtn} onClick={onClose}>
          <img src={closeBtn} alt="close" />
        </button>
      </div>
    </div>
  );
};

export default AlertMessage;
