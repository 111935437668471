import React from "react";
import styles from "./loginModal.module.scss";
import Modal from "src/_elements/modal/modal";
import LoginComponent from "src/pages/auth/login/loginComponent/loginComponent";
import { setIsShowLoginModalAC } from "src/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { ILoginModal } from "../../interfaces/interfaces";

const LoginModal: React.FC<ILoginModal> = ({ type }: ILoginModal) => {
  const dispatch = useDispatch();

  const isShowLoginModal = useSelector((state: any) => state.isShowLoginModal);

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShowLoginModal}
      onClose={() => dispatch(setIsShowLoginModalAC(false))}
    >
      <div className={styles.content}>
        <LoginComponent type={type} />
      </div>
    </Modal>
  );
};

export default LoginModal;
