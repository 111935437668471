import styles from "./thisIsYourToolCard.module.scss";
import arrowIncreaseImg from "src/assets/salesFunnel/arrowIncrease.svg";

const ThisIsYourToolCard: React.FC<any> = ({
  className,
  title,
  subtitle,
  img,
}) => {
  return (
    <div className={className}>
      <img className={styles.labelImg} src={arrowIncreaseImg} alt="arrow" />
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <div>
        <img className={styles.contentImg} src={img} alt={title} />
      </div>
    </div>
  );
};

export default ThisIsYourToolCard;
