import { Link, useLocation } from "react-router-dom";
import styles from "./paymentSuccess.module.scss";
import paymentSuccessIcon from "src/assets/paymentSuccess.svg";
import ROUTES from "src/_utils/routes/routes";
import { useDispatch } from "react-redux";
import { setIsShowCalendlyModalAC } from "src/store/actions";
import CalendlyModal from "../homePage/payments/registerModal/calendlyModal";
import { useEffect } from "react";

const PaymentSuccess: React.FC<any> = ({ isTrial }: any) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const isOnboarding = searchParams.get("onboarding") === "true";

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const purchaseSuccess = localStorage.getItem("purchaseSuccess");

  //   if (purchaseSuccess !== "true") {
  //     navigate("/");
  //   }
  // }, [navigate]);

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem("purchaseSuccess");
  //   };
  // });

  useEffect(() => {
    const pixelId = "912896493628263";
    const scriptId = `facebook-pixel-${pixelId}`;

    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${pixelId}');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement("noscript");
      noscript.id = scriptId + "-noscript";
      noscript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1" />
      `;
      document.body.appendChild(noscript);
    }
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <img src={paymentSuccessIcon} alt="success" />
        <h1>Payment was successful!</h1>
        {!isOnboarding ? (
          isTrial ? (
            <a
              className={styles.btn}
              href={`https://${process.env.REACT_APP_DOMAIN}`}
              rel="noopener noreferrer"
            >
              Click here to continue
            </a>
          ) : (
            <Link className={styles.btn} to={ROUTES.SETTINGS}>
              Click here to continue
            </Link>
          )
        ) : (
          <button
            className={styles.btn}
            onClick={() =>
              dispatch(
                setIsShowCalendlyModalAC({
                  show: true,
                  url: process.env.REACT_APP_CALENDLY_BOOK_A_CALL!,
                })
              )
            }
          >
            Book a call
          </button>
        )}
      </div>
      <CalendlyModal />
    </>
  );
};

export default PaymentSuccess;
