import styles from "./paymentType.module.scss";
import stylesTrial from "./paymentTypeTrial.module.scss";

const PaymentType: React.FC<any> = ({
  selectedPaymentType,
  setselectedPaymentType,
  isTrial,
}: any) => {
  const MONTH_PAYMENT_TYPE = "month";
  const YEAR_PAYMENT_TYPE = "year";

  return (
    <div className={styles.wrapper}>
      <div
        className={
          isTrial ? stylesTrial.paymentTypeWrapper : styles.paymentTypeWrapper
        }
      >
        <button
          onClick={() => setselectedPaymentType(MONTH_PAYMENT_TYPE)}
          className={`${
            isTrial ? stylesTrial.switchButton : styles.switchButton
          } ${
            selectedPaymentType === MONTH_PAYMENT_TYPE
              ? isTrial
                ? stylesTrial.selectedSwitchButton
                : styles.selectedSwitchButton
              : ""
          }`}
        >
          Pay Monthly
        </button>
        <button
          onClick={() => setselectedPaymentType(YEAR_PAYMENT_TYPE)}
          className={`${
            isTrial ? stylesTrial.switchButton : styles.switchButton
          } ${
            selectedPaymentType === YEAR_PAYMENT_TYPE
              ? isTrial
                ? stylesTrial.selectedSwitchButton
                : styles.selectedSwitchButton
              : ""
          }`}
        >
          Pay Yearly
        </button>
      </div>
      <p className={isTrial ? stylesTrial.footer : styles.footer}>
        2 months free!* Billed Annually*
      </p>
    </div>
  );
};

export default PaymentType;
