import styles from "./proOnboardingSetup.module.scss";
import CallBooking from "../homePage/payments/callBooking/callBooking";
import Header from "../homePage/header/header";
import { Helmet } from "react-helmet";

const ProOnboardingSetup = () => {
  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Get Pro Onboarding Setup</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link
          rel="canonical"
          href="https://leadquiz.com/pro-onboarding-setup"
        />
        <meta property="og:title" content="LeadQuiz | Get Pro Onboarding Setup" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://leadquiz.com/pro-onboarding-setup"
        />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <CallBooking isWithoutPlan />
      </div>
    </div>
  );
};

export default ProOnboardingSetup;
