import React, { useState, useEffect } from "react";
import styles from "./animatedPopup.module.scss";
import ReactDOM from "react-dom";
import { IAnimatedPopup } from "../interfaces/interfaces";

const AnimatedPopup: React.FC<IAnimatedPopup> = ({
  isOpen,
  onClose,
  children,
}: IAnimatedPopup) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsAnimating(true), 100);
    } else {
      setIsAnimating(false);
      const timeout = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      className={`${styles.overlay} ${
        isAnimating ? styles.showOverlay : styles.hideOverlay
      }`}
      onClick={onClose}
    >
      <div
        className={`${styles.modal} ${
          isAnimating ? styles.showModal : styles.hideModal
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className={styles.closeButton} onClick={onClose}>
          ✕
        </button>
        {children}
      </div>
    </div>,
    document.getElementById("popup-root") as HTMLElement
  );
};

export default AnimatedPopup;
