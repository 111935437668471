import React from "react";
import styles from "./pricingModal.module.scss";
import Modal from "src/_elements/modal/modal";
import Payments from "../../payments/payments";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowPricingModalAC } from "src/store/actions";

const PricingModal: React.FC<any> = ({ isTrial }) => {
  const dispatch = useDispatch();

  const isShowPricingModal = useSelector(
    (state: any) => state.isShowPricingModal
  );

  return (
    <Modal
      className={styles.wrapper}
      isShow={isShowPricingModal}
      onClose={() => dispatch(setIsShowPricingModalAC(false))}
    >
      <div className={styles.content}>
        <Payments isTrial={isTrial} isTrialModal />
      </div>
    </Modal>
  );
};

export default PricingModal;
