import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import companyLogo from "src/assets/homePage/companyLogo.png";
import facebook from "src/assets/homePage/facebook.svg";
import instagram from "src/assets/homePage/instagram.svg";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import CalendlyModal from "../payments/registerModal/calendlyModal";
import styles from "./footer.module.scss";

const Footer: React.FC<any> = ({ isTrial }: any) => {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.contentBlock}>
            <img
              width="170px"
              height="70px"
              src={companyLogo}
              alt="LeadQuiz logo"
            />
            <p>
              LeadQuiz helps you qualify leads, and collect their information.
            </p>
            <p>
              <a
                className={styles.emailLink}
                href="mailto:Support@leadquiz.com"
              >
                Support@leadquiz.com
              </a>
            </p>
          </div>
          {!isTrial && (
            <div className={styles.contentBlock}>
              <b>Navigation</b>
              <ul className={styles.navigation}>
                <li>
                  <Link to={ROUTES.HOME}>Home</Link>
                </li>
                <li>
                  <Link to={ROUTES.HOW_IT_WORKS}>How it works</Link>
                </li>
                <li>
                  <Link to={ROUTES.WHO_WE_SERVE}>Who we serve</Link>
                </li>
                <li>
                  <Link to={ROUTES.PRICING}>Pricing</Link>
                </li>
              </ul>
            </div>
          )}
          <div className={`${styles.contentBlock} ${styles.socials}`}>
            <b>Socials</b>
            <a
              href="https://www.facebook.com/leadquiz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width="12px" height="30px" src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/leadquiz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width="24px" height="30px" src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
        <button
          onClick={
            token
              ? () => dispatch(setIsShowPricingModalAC(true))
              : () => dispatch(setIsShowRegisterModalAC(true))
          }
          className={styles.getStarted}
        >
          {isTrial ? "Get started for free" : "Get started"}
        </button>
        <CalendlyModal />
      </div>
      <div className={styles.copyright}>
        <div className={styles.copyrightContent}>
          <p>
            <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> |{" "}
            <Link to={ROUTES.TERMS_OF_USE}>Terms of Use</Link> |{" "}
            <Link to={ROUTES.SUPPORT}>Support</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
