import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Input from "src/_elements/input/input";
import { signUpApi } from "src/_utils/api/api";
import { validateEmail } from "src/_utils/emailValidator";
import { ISignUpPayload } from "src/_utils/interfaces/interfaces";
import { PASSWORD_ERROR_MESSAGES } from "src/_utils/passwordErrorMessages";
import {
  validatePasswordDigit,
  validatePasswordLatinSymbols,
  validatePasswordLength,
  validatePasswordSpecialSymbol,
  validatePasswordUpperCase,
} from "src/_utils/passwordValidator";
import ROUTES from "src/_utils/routes/routes";
import companyLogo from "src/assets/homePage/companyLogo.png";
import googleLogo from "src/assets/signupPage/googleLogo.webp";
import {
  setGoogleAuthErrorAC,
  setIsShowLoginModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import styles from "./signupComponent.module.scss";
import AUTH_TYPES from "src/_utils/authTypes";
import { isWebView } from "src/_utils/isWebView";

const SignUpComponent: React.FC<any> = ({ type }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const isGoogleAuthError = useSelector(
    (state: any) => state.isGoogleAuthError
  );

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [userNameError, setUserNameError] = useState(false);
  const [userEmailError, setUserEmailError] = useState(false);
  const [userPasswordError, setUserPasswordError] = useState(false);

  const [isSuccessfull, setIsSuccessfull] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);
  const [isAgreePolicy, setIsAgreePolicy] = useState(false);

  useEffect(() => {
    if (token) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  const registedUser = () => {
    dispatch(setGoogleAuthErrorAC(false));
    setIsLoading(true);

    if (validateEmail(userEmail)) {
      setErrorMessage("");
      setUserEmailError(false);
    } else {
      setUserEmailError(true);
      setErrorMessage("Invalid email address");
      setIsLoading(false);
    }

    if (!validatePasswordSpecialSymbol(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage(PASSWORD_ERROR_MESSAGES.specialCharacter);
      setIsLoading(false);
    } else if (!validatePasswordUpperCase(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage(PASSWORD_ERROR_MESSAGES.uppercaseCharacter);
      setIsLoading(false);
    } else if (!validatePasswordDigit(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage(PASSWORD_ERROR_MESSAGES.numericCharacter);
      setIsLoading(false);
    } else if (!validatePasswordLength(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage(PASSWORD_ERROR_MESSAGES.charactersLength);
      setIsLoading(false);
    } else if (!validatePasswordLatinSymbols(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage(PASSWORD_ERROR_MESSAGES.latinSymbols);
      setIsLoading(false);
    } else if (!isAgreeTerms || !isAgreePolicy) {
      if (!(isAgreeTerms && isAgreePolicy)) {
        setErrorMessage("Please agree with terms of use and privacy policy");
      }

      if (!isAgreeTerms && isAgreePolicy) {
        setErrorMessage("Please agree with terms of use");
      }

      if (isAgreeTerms && !isAgreePolicy) {
        setErrorMessage("Please agree with privacy policy");
      }
      setIsLoading(false);
    } else {
      setErrorMessage("");
      setUserPasswordError(false);

      let payload: ISignUpPayload;

      if (type === AUTH_TYPES.TRIAL) {
        payload = {
          login: userEmail,
          password: userPassword,
          name: userName,
          isSalesPage: true,
        };
      } else if (type === AUTH_TYPES.AGENCY) {
        payload = {
          login: userEmail,
          password: userPassword,
          name: userName,
          isAgencyPage: true,
        };
      } else {
        payload = {
          login: userEmail,
          password: userPassword,
          name: userName,
        };
      }

      signUpApi(payload)
        .then(() => {
          setIsSuccessfull(true);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);

          console.error("Error fetching data:", error);

          if (error.response.data.error.message === "ERROR_USER_EXIST") {
            setUserEmailError(true);
            setErrorMessage("A user with this email already exists.");
          } else if (error.response.data.error.message) {
            setErrorMessage(error.response.data.error.message);
          }

          if (userName === "") {
            setUserNameError(true);
          }
          if (userEmail === "") {
            setUserEmailError(true);
          }
          if (userPassword === "") {
            setUserPasswordError(true);
          }
          if (userName === "" || userEmail === "" || userPassword === "") {
            setErrorMessage("Please fill in all fields.");
          }
        });
    }
  };

  const handleGoogleSignUp = () => {
    if (isAgreeTerms && isAgreePolicy) {
      localStorage.setItem("isFirstLogin", "true");
      window.location.replace(
        type === AUTH_TYPES.TRIAL
          ? process.env.REACT_APP_API_URL + ROUTES.GOOGLE_AUTH_TRY
          : type === AUTH_TYPES.AGENCY
          ? process.env.REACT_APP_API_URL + ROUTES.GOOGLE_AUTH_AGENCY
          : process.env.REACT_APP_API_URL + ROUTES.GOOGLE_AUTH
      );
    } else {
      if (!(isAgreeTerms && isAgreePolicy)) {
        setErrorMessage("Please agree with terms of use and privacy policy");
      }

      if (!isAgreeTerms && isAgreePolicy) {
        setErrorMessage("Please agree with terms of use");
      }

      if (isAgreeTerms && !isAgreePolicy) {
        setErrorMessage("Please agree with privacy policy");
      }
    }
  };

  const handleOpenLoginModal = () => {
    dispatch(setIsShowLoginModalAC(true));
    dispatch(setIsShowRegisterModalAC(false));
  };

  return (
    <div className={styles.wrapper}>
      <Link to={ROUTES.HOME}>
        <img width="170px" height="68px" src={companyLogo} alt="company logo" />
      </Link>
      <div className={styles.inputsWrapper}>
        <Input
          value={userName}
          onChange={(e) => setUserName(e.currentTarget.value)}
          placeholder="Name"
          label="Name:"
          className={userNameError ? styles.errorInput : ""}
        />
        <Input
          value={userEmail}
          type="email"
          onChange={(e) => setUserEmail(e.currentTarget.value)}
          placeholder="Email Address"
          autoComplete="email"
          label="Email Address:"
          className={userEmailError ? styles.errorInput : ""}
        />
        <Input
          value={userPassword}
          type="password"
          onChange={(e) => setUserPassword(e.currentTarget.value)}
          placeholder="Password"
          label="Password:"
          className={userPasswordError ? styles.errorInput : ""}
        />
      </div>
      {isGoogleAuthError && (
        <p className={styles.errorMessage}>Email already in use.</p>
      )}
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      {isSuccessfull && (
        <p className={styles.successMessage}>
          A confirmation message has been sent to your email.
        </p>
      )}
      <div className={styles.termsWrapper}>
        <div className={styles.checkboxWrapper}>
          <input
            type="checkbox"
            checked={isAgreeTerms}
            onChange={(e: any) => setIsAgreeTerms(e.target.checked)}
          />
          <span>
            I accept the{" "}
            <Link target="_blank" to={ROUTES.TERMS_OF_USE}>
              general terms of use
            </Link>
          </span>
        </div>
        <div className={styles.checkboxWrapper}>
          <input
            type="checkbox"
            checked={isAgreePolicy}
            onChange={(e: any) => setIsAgreePolicy(e.target.checked)}
          />
          <span>
            I accept the{" "}
            <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>
              privacy policy
            </Link>
          </span>
        </div>
      </div>
      <button className={styles.btn} onClick={registedUser}>
        {!isLoading ? (
          "Sign up"
        ) : (
          <RotatingLines
            visible={true}
            width="30"
            strokeWidth="5"
            animationDuration="0.75"
            strokeColor="white"
          />
        )}
      </button>
      <p className={styles.labelOr}>OR</p>
      <div className={styles.alternativeSignUpVariants}>
        {!isWebView() ? (
          <button className={styles.button} onClick={handleGoogleSignUp}>
            <img
              height="40px"
              width="40px"
              src={googleLogo}
              alt="google logo"
            />
            Signup with Google
          </button>
        ) : (
          <></>
        )}
        <button onClick={handleOpenLoginModal} className={styles.button}>
          Already have an Account?
        </button>
      </div>
    </div>
  );
};

export default SignUpComponent;
