const ROUTES = {
  HOME: "/",
  SIGNUP: "/signup",
  ACTIVATE_USER: "/activate-user",
  LOGIN: "/login",
  GOOGLE_AUTH: "/auth/google",
  GOOGLE_AUTH_TRY: "/auth/google-try",
  GOOGLE_AUTH_AGENCY: "/auth/google-agency",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_EMAIL: "/change-email",
  RESET_PASSWORD: "/reset-password",
  FORM_BUILDER: "/form-builder",
  FORM_VIEW: "/form-view",
  HOW_IT_WORKS: "/how-it-works",
  WHO_WE_SERVE: "/who-we-serve",
  PRICING: "/pricing",
  ADMIN_PAGE: "/admin-page",
  FORMS: "/forms",
  SETTINGS: "/settings",
  PAYMENT_SUCCESS: "/success/*",
  LEADS: "/leads",
  EDIT_FORM: "/edit-form",
  CREATE_FORM_TEMPLTE: "/create-form-template",
  EDIT_FORM_TEMPLTE: "/edit-form-template",
  GENERAL_CONTRACTING: "/general-contracting",
  HVAC: "/HVAC",
  ROOFING: "/roofing",
  SOLAR: "/solar",
  PLUMBING: "/plumbing",
  ELECTRICAL: "/electrical",
  PAINTING: "/painting",
  PEST_CONTROL: "/pest-control",
  LANDSCAPING_AND_LAWN_CARE: "/landscaping-and-lawn-care",
  POOL_CONSTRUCTION: "/pool-construction",
  CLEANING_SERVICES: "/cleaning-services",
  EPOXY_COATING: "/epoxy-coating",
  FLOORING: "/flooring",
  HOME_RENOVATION: "/home-renovation",
  GARAGE_DOOR_SERVICES: "/garage-door-services",
  HOLIDAY_LIGHT_INSTALLATION: "/holiday-light-installation",
  WINDOW_CLEANING: "/window-cleaning",
  HOME_SECURITY: "/home-security",
  APPLIANCE_REPAIR: "/appliance-repair",
  INTERIOR_DESIGN: "/interior-design",
  FURNITURE_ASSEMBLY: "/furniture-assembly",
  HOME_ORGANIZATION: "/home-organization",
  MASONRY: "/masonry",
  DECK_AND_PATIO_CONSTRUCTION: "/deck-and-patio-construction",
  FENCING: "/fencing",
  WATERPROOFING: "/waterproofing",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
  FOR_AGENCIES: "/for-agencies",
  THANK_YOU: "/thank-you",
  SUPPORT: "/support",
  HOW_TO_CREATE_FORM: "/support/how-to-create-form",
  HOW_TO_ADD_PRIVACY_POLICY_AND_BUSINESS_INFO:
    "/support/how-to-add-privacy-policy-and-business-info",
  HOW_TO_INTEGRATE_JOBBER: "/support/how-to-integrate-jobber",
  HOW_TO_INTEGRATE_WORKIZ: "/support/how-to-integrate-workiz",
  HOW_TO_INTEGRATE_LEAD_CONNECTOR: "/support/how-to-integrate-lead-connector",
  HOW_TO_INTEGRATE_BUILDER_PRIME: "/support/how-to-integrate-builder-prime",
  HOW_TO_TRACK_FACEBOOK_AD: "/support/how-to-track-facebook-ad-conversions",
  HOW_TO_TRACK_GOOGLE_ADS: "/support/how-to-track-google-ads",
  PRO_ONBOARDING_SETUP: "/pro-onboarding-setup",
};

export default ROUTES;
