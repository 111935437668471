import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ActionModal from "src/_elements/actionModal/actionModal";
import {
  apiRoutes,
  cancelSubscriptionAtEndApi,
  changePlanApi,
  getSubscriptionScheduleApi,
  isPaidCallAvailableApi,
  isTrialAvailableApi,
  reactivatePlatApi,
} from "src/_utils/api/api";
import pointIcon from "src/assets/homePage/point.svg";
import {
  observeNextPlanChangedAC,
  setIsShowCalendlyModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import styles from "./paymentPlan.module.scss";
import stylesTrial from "./paymentPlanTrial.module.scss";
import formIcon from "src/assets/form.svg";
import plusIcon from "src/assets/plus.svg";
import minusIcon from "src/assets/minus.svg";
import AnimatedPopup from "src/_elements/animatedPopup/animatedPopup";
import CallBooking from "../callBooking/callBooking";
import ROUTES from "src/_utils/routes/routes";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentPlan: React.FC<any> = ({
  title,
  price,
  list,
  maxDomains,
  maxSubmissionsPerMonth,
  id,
  priceId,
  isBought,
  isNextPlan,
  endBehavior,
  isPlanChanged,
  isCurrentPlanChanged,
  setIsCurrentPlanChanged,
  totalAdditionalSubmissionsCount,
  additionalPurchases,
  isNeedToContactSales,
  isNeedUpdatePlan,
  isCurrentPlanAvailable,
  isYearly,
  isPersonalPlan,
  isPlanPermanent,
  pricePerForm,
  boughtPlanFormsCount,
  isTrial,
}: any) => {
  const [listArray, setListArray] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeNextPlanLoading, setIsChangeNextPlanLoading] = useState(false);
  const [isShowUnsubscribeModal, setIsShowUnsubscribeModal] = useState(false);
  const [isShowReactivateModal, setIsShowReactivateModal] = useState(false);
  const [currentEndBehavior, setCurrentEndBehavior] = useState(endBehavior);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [formsCount, setFormsCount] = useState(3);
  const [isShowCallBookingPopup, setIsShowCallBookingPopup] = useState(false);
  const [isUserChangingPlan, setIsUserChangingPlan] = useState(false);
  const [isUserCanUseTrial, setIsUserCanUseTrial] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");

  const observeNextPlanChanged = useSelector(
    (state: any) => state.observeNextPlanChanged
  );

  useEffect(() => {
    if (boughtPlanFormsCount) {
      setFormsCount(boughtPlanFormsCount);
    }
  }, [boughtPlanFormsCount]);

  useEffect(() => {
    setCurrentEndBehavior(endBehavior);
  }, [endBehavior]);

  useEffect(() => {
    setIsCurrentPlanChanged && setIsCurrentPlanChanged(isPlanChanged);
  }, [isPlanChanged]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("purchaseSuccess");
    };
  });

  const handleSetPlanDescriptionList = () => {
    if (!list) {
      const listArr = [
        `Custom Questions`,
        `${pricePerForm ? formsCount : maxDomains} Custom ${
          maxDomains === 1 ? "Domain" : "Domains"
        }`,
        `${
          totalAdditionalSubmissionsCount
            ? maxSubmissionsPerMonth + totalAdditionalSubmissionsCount
            : maxSubmissionsPerMonth
        } Submissions/Month`,
      ];

      setListArray(
        listArr &&
          listArr.map((el: string, index: number) => (
            <li key={index}>
              <img
                style={
                  isTrial
                    ? {}
                    : { filter: `invert(${title === "Pro" ? 1 : 0})` }
                }
                src={pointIcon}
                alt="point"
              />
              <p>{el}</p>
            </li>
          ))
      );
    }
  };

  useEffect(() => {
    if (list) {
      setListArray(
        list &&
          list.map((el: string, index: number) => (
            <li key={index}>
              <img
                style={
                  isTrial
                    ? {}
                    : { filter: `invert(${title === "Pro" ? 1 : 0})` }
                }
                src={pointIcon}
                alt="point"
              />
              <p>{el}</p>
            </li>
          ))
      );
    } else {
      handleSetPlanDescriptionList();
    }
  }, [maxDomains, maxSubmissionsPerMonth]);

  useEffect(() => {
    if (token) {
      isTrialAvailableApi().then((res) => {
        setIsUserCanUseTrial(res.data.data.canUseTrial);
      });
    }
  }, [token]);

  const handleCloseUnsubscribeModal = () => {
    setIsShowUnsubscribeModal(false);
  };

  const handleCloseReactivateModal = () => {
    setIsShowReactivateModal(false);
  };

  const handleGetPlan = async () => {
    if (token) {
      setIsShowCallBookingPopup(true);
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  const handleOnlyChangePlan = () => {
    setIsChangeNextPlanLoading(true);

    let payload;

    if (pricePerForm) {
      payload = {
        newPriceId: priceId,
        newPlanId: id,
        formsCount: formsCount,
      };
    } else {
      payload = {
        newPriceId: priceId,
        newPlanId: id,
      };
    }
    changePlanApi(payload)
      .then(() => {
        dispatch(observeNextPlanChangedAC(!observeNextPlanChanged));
        setIsChangeNextPlanLoading(false);
        handleCloseCallBookingModal();
        navigate(ROUTES.SETTINGS);
      })
      .catch((err) => console.log(err));
  };

  const handleGetAdditionalPurchase = async (stripePriceId: string) => {
    const token = localStorage.getItem("token");

    const stripePromise = loadStripe(
      process.env.REACT_APP_PUBLISHABLE_KEY as string
    );

    const stripe = await stripePromise;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreatePurchaseAdditionalSession}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          stripePriceId,
        }),
      }
    );

    localStorage.setItem("purchaseSuccess", "true");

    const data = await response.json();

    const sessionId = data.data.sessionId;

    //@ts-ignore
    const { error } = await stripe?.redirectToCheckout({
      sessionId,
    });
  };

  const handleChangePlan = () => {
    isPaidCallAvailableApi().then((res) => {
      if (res.data.data.available) {
        handleOnlyChangePlan();
      } else {
        if (token) {
          setIsShowCallBookingPopup(true);
          setIsUserChangingPlan(true);
        } else {
          dispatch(setIsShowRegisterModalAC(true));
        }
      }
    });
  };

  const showPrice = price && price.toString().padStart(3, "0");
  const showPricePerForm =
    pricePerForm && pricePerForm.toString().padStart(3, "0");

  const handleUnsubscribe = () => {
    setIsShowUnsubscribeModal(false);
    setIsLoading(true);
    cancelSubscriptionAtEndApi().then(() => {
      getSubscriptionScheduleApi().then((res) => {
        setCurrentEndBehavior(res.data.data[0].end_behavior);
        setIsCurrentPlanChanged(res.data.data[0].phases[1]);
        setIsLoading(false);
      });
    });
  };

  const handleReactivatePlan = () => {
    setIsShowReactivateModal(false);
    setIsLoading(true);
    reactivatePlatApi().then(() => {
      getSubscriptionScheduleApi().then((res) => {
        setCurrentEndBehavior(res.data.data[0].end_behavior);
        setIsCurrentPlanChanged(res.data.data[0].phases[1]);
        setIsLoading(false);
      });
    });
  };

  const additionalPurchasesList =
    additionalPurchases &&
    additionalPurchases.map((el: any, index: number) => (
      <button
        key={index}
        onClick={() => handleGetAdditionalPurchase(el.stripePriceId)}
      >
        {el.name}
      </button>
    ));

  useEffect(() => {
    if (showPrice && !pricePerForm) {
      setCurrentPrice(
        parseFloat(showPrice.slice(0, -2) + "." + showPrice.slice(-2))
      );
    } else if (pricePerForm) {
      setCurrentPrice(
        parseFloat(
          showPricePerForm.slice(0, -2) + "." + showPricePerForm.slice(-2)
        )
      );
    }
  }, [showPrice, showPricePerForm]);

  useEffect(() => {
    if (formsCount) {
      handleSetPlanDescriptionList();
    }
  }, [formsCount]);

  useEffect(() => {
    if (isYearly) {
      setCurrentPrice((prevState) => Math.ceil(prevState / 12));
    }
  }, [isYearly]);

  const handleChangeFormsCount = (e: any) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      const numericValue = Number(newValue);
      setFormsCount(newValue === "" || numericValue < 3 ? 3 : numericValue);
    }
  };

  const incrementForms = () => {
    setFormsCount((prevValue) => prevValue + 1);
  };

  const decrementForms = () => {
    setFormsCount((prevValue) => Math.max(3, prevValue - 1));
  };

  const handleCloseCallBookingModal = () => {
    setIsShowCallBookingPopup(false);
  };

  return (
    <>
      <div
        className={`${isTrial ? stylesTrial.wrapper : styles.wrapper} ${
          isTrial ? "" : title !== "Pro" ? styles.lightPlan : styles.darkPlan
        }`}
      >
        <p className={isTrial ? stylesTrial.title : styles.title}>{title}</p>
        {isPersonalPlan && (
          <p className={styles.personalPlanLabel}> (Personal offer)</p>
        )}
        {isNeedUpdatePlan && (
          <div className={styles.alert}>
            <p>
              price has changed, please re-subscribe to current or another plan
              from the next period
            </p>
          </div>
        )}
        {pricePerForm
          ? (pricePerForm || pricePerForm === 0) &&
            !isNeedToContactSales && (
              <div>
                {!boughtPlanFormsCount && (
                  <p>
                    <span className={styles.pricePerForm}>
                      {pricePerForm === 0
                        ? "$0.00"
                        : `$${Number(currentPrice.toFixed(2))}`}
                    </span>
                    /form
                  </p>
                )}
                <p>
                  <span className={isTrial ? stylesTrial.price : styles.price}>
                    {pricePerForm === 0
                      ? "$0.00"
                      : `$${Number(currentPrice.toFixed(2)) * formsCount}`}
                  </span>
                  /month
                </p>
              </div>
            )
          : (price || price === 0) &&
            !isNeedToContactSales && (
              <p>
                <span className={isTrial ? stylesTrial.price : styles.price}>
                  {price === 0 ? "$0.00" : `$${currentPrice.toFixed(2)}`}
                </span>
                /month
              </p>
            )}
        {pricePerForm && !boughtPlanFormsCount && !isNextPlan ? (
          <div className={styles.counterOuterWrap}>
            <div className={styles.counterWrap}>
              <div className={styles.counterInputWrap}>
                <div className={styles.counterImgWrap}>
                  <img src={formIcon} alt="form" />
                </div>
                <input
                  type="text"
                  inputMode="numeric"
                  value={formsCount}
                  onChange={handleChangeFormsCount}
                />
              </div>
              <div className={styles.formsCounterBtnsWrap}>
                <div className={styles.decrementForms}>
                  <button onClick={decrementForms} disabled={formsCount <= 3}>
                    <img src={minusIcon} alt="minus" />
                  </button>
                </div>
                <div className={styles.incrementForms}>
                  <button onClick={incrementForms}>
                    <img src={plusIcon} alt="plus" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={isTrial ? stylesTrial.content : ""}>
          <div
            className={
              isTrial ? stylesTrial.buttonWrapper : styles.buttonWrapper
            }
          >
            {isTrial && (
              <p className={stylesTrial.trialLabel}>14 day free trial</p>
            )}
            {isUserCanUseTrial &&
              title !== "Enterprise" &&
              location.pathname !== ROUTES.SETTINGS && (
                <p className={styles.defaultTrialLabel}>14 day free trial</p>
              )}
            {!isNextPlan &&
              !isBought &&
              !isNeedToContactSales &&
              !isCurrentPlanAvailable &&
              (title !== "Enterprise" || (pricePerForm && formsCount < 50)) && (
                <button onClick={handleGetPlan}>
                  Get {title.toLowerCase()} plan
                </button>
              )}
            {title === "Enterprise" || (pricePerForm && formsCount > 49) ? (
              <button
                onClick={() =>
                  dispatch(
                    setIsShowCalendlyModalAC({
                      show: true,
                      url: process.env.REACT_APP_CALENDLY_URL!,
                    })
                  )
                }
              >
                Contact Us
              </button>
            ) : (
              <></>
            )}
            {!isNextPlan && isNeedToContactSales && (
              <button>Contact sales</button>
            )}
            {isBought &&
              !isCurrentPlanChanged &&
              !isPlanPermanent &&
              currentEndBehavior !== "cancel" && (
                <button onClick={() => setIsShowUnsubscribeModal(true)}>
                  {!isLoading ? (
                    "Unsubscribe"
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              )}
            {isBought &&
              (isCurrentPlanChanged || currentEndBehavior === "cancel") &&
              !isNeedUpdatePlan && (
                <button onClick={() => setIsShowReactivateModal(true)}>
                  {!isLoading ? (
                    "Reactivate"
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              )}
            {!isNextPlan &&
              !isBought &&
              !isNeedToContactSales &&
              isCurrentPlanAvailable &&
              formsCount < 50 && (
                <button onClick={handleChangePlan}>
                  {!isChangeNextPlanLoading ? (
                    `Change to ${title.toLowerCase()}`
                  ) : (
                    <RotatingLines
                      visible={true}
                      width="30"
                      strokeWidth="5"
                      animationDuration="0.75"
                      strokeColor="white"
                    />
                  )}
                </button>
              )}
          </div>
          <ul>{listArray}</ul>
          {isBought && additionalPurchasesList[0] && (
            <>
              <p className={styles.additionalPurchasesTitle}>
                Additional purchases:{" "}
              </p>
              <div className={styles.additionalPurchasesWrapper}>
                {additionalPurchasesList}
              </div>
            </>
          )}
        </div>
      </div>
      <ActionModal
        title={`Unsubscribe from plan ${title}?`}
        subtitle="This will unsubscribe you from your current plan, but it will remain
          in effect until your next payment date, after which the plan will
          cease and no money will be charged."
        isShowModal={isShowUnsubscribeModal}
        handleCloseModal={handleCloseUnsubscribeModal}
        handleAction={handleUnsubscribe}
        actionButtonText="Unsubscribe"
      />
      <ActionModal
        title={`Reactivate plan ${title}?`}
        subtitle="This will restore your plan subscription."
        isShowModal={isShowReactivateModal}
        handleCloseModal={handleCloseReactivateModal}
        handleAction={handleReactivatePlan}
        actionButtonText="Reactivate"
      />
      <AnimatedPopup
        isOpen={isShowCallBookingPopup}
        onClose={handleCloseCallBookingModal}
      >
        <CallBooking
          isUserChangingPlan={isUserChangingPlan}
          handleOnlyChangePlan={handleOnlyChangePlan}
          pricePerForm={pricePerForm}
          priceId={priceId}
          id={id}
          formsCount={formsCount}
          isTrial={isTrial}
        />
      </AnimatedPopup>
    </>
  );
};

export default PaymentPlan;
